.pa-row-container{
	position: relative;
	&.pa-parallax{
		.pa-row-inner{
			background-repeat: no-repeat !important;
			background-attachment: fixed;
		}
	}
	.pa-bg-video{
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		overflow: hidden;
		z-index: 0;
	}
}
.pa-row-inner {
	> div{
		position: relative;
	}
}